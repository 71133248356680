import dynamic from 'next/dynamic'
import React from 'react'

import { useIntl } from 'react-intl'
import { Link } from 'solito/link'

import { Box, Container, Theme, useMediaQuery } from '@mui/material'

import Stack from 'app/components/base/Stack'
import Text from 'app/components/base/Text'
import SocialLinksBlock from 'app/components/common/SocialLinksBlock'

import DownloadOurApp from './components/DownloadOurApp'
import FooterBlockContainer from './components/FooterBlockContainer'
import FooterLinksBlock from './components/FooterLinksBlock'
import PaymentsAndMembers from './components/PaymentsAndMembers'
import SearchCategories from './components/SearchCategories'
import { useFooter } from './hooks/useFooter'

const Logo = dynamic(() => import('app/assets/images/dark-logo.svg'))

interface FooterProps {
  isPoweredFooter?: boolean
  hideSearchCategories?: boolean
}

const Footer = ({
  isPoweredFooter,
  hideSearchCategories = false
}: FooterProps): JSX.Element => {
  const { formatMessage } = useIntl()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const { staticPages, year } = useFooter()

  return (
    <>
      {!hideSearchCategories && <SearchCategories />}

      <Box
        sx={{
          pt: { xs: 4, md: 8 },
          pb: 5,
          borderTop: { xs: 'none', md: '1px solid' },
          borderColor: { xs: 'none', md: 'stroke.main' }
        }}
      >
        <Container maxWidth="lg">
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 4, md: 2 }}
            sx={{ justifyContent: 'space-between' }}
          >
            <Stack
              direction="column"
              spacing={2.5}
              sx={{ mb: { xs: 4, md: 0 } }}
            >
              <Stack direction="column" spacing={1}>
                {isPoweredFooter && (
                  <Text variant="subtitle2">
                    {formatMessage({ defaultMessage: 'Powered by' })}
                  </Text>
                )}

                <Link href="/" viewProps={{ 'aria-label': 'Go to homepage' }}>
                  <Logo />
                </Link>
              </Stack>

              <Stack direction="column">
                <Text size={{ xs: 'subtitle1' }} sx={{ color: 'text.third' }}>
                  {formatMessage(
                    { defaultMessage: 'Online store {year}.' },
                    { year }
                  )}
                </Text>

                <Text size={{ xs: 'subtitle1' }} sx={{ color: 'text.third' }}>
                  {formatMessage({
                    defaultMessage: 'All rights are reserved.'
                  })}
                </Text>
              </Stack>

              <PaymentsAndMembers />

              {isMobile && <DownloadOurApp />}
            </Stack>

            <Stack
              direction="row"
              sx={{
                flexWrap: 'wrap',
                gap: { xs: 4, sm: 9, md: 3 },
                justifyContent: { xs: 'flex-start', lg: 'space-between' }
              }}
            >
              {staticPages?.map((item) => (
                <FooterLinksBlock
                  key={item.name}
                  title={item.name}
                  items={item.static_pages}
                />
              ))}
            </Stack>

            <Stack direction="column" spacing={4}>
              {!isMobile && <DownloadOurApp />}

              <FooterBlockContainer
                title={formatMessage({ defaultMessage: 'Our Social media' })}
              >
                <SocialLinksBlock spacing={2} />
              </FooterBlockContainer>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  )
}

export default Footer
