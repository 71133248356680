import { defineMessages } from 'react-intl'

import { STATIC_PAGE_VISIBILITY } from './status.constants'

const messages = defineMessages({
  contactUs: { defaultMessage: 'Contact Us' },
  blog: { defaultMessage: 'Blog' },
  helpCenter: { defaultMessage: 'Help Centre' }
})

export const ADDITIONAL_STATIC_PAGES = {
  contactUs: {
    title: messages.contactUs,
    slug: 'contact-us',
    visibility: STATIC_PAGE_VISIBILITY.marketplace
  },
  blog: {
    title: messages.blog,
    slug: 'blog',
    visibility: STATIC_PAGE_VISIBILITY.marketplace
  },
  helpCenter: {
    title: messages.helpCenter,
    slug: 'help-centre',
    visibility: STATIC_PAGE_VISIBILITY.marketplace
  }
}
