import Link from 'next/link'

import Stack from 'app/components/base/Stack'
import { ADDITIONAL_STATIC_PAGES } from 'app/utils/constants/staticPage.constants'

import FooterBlockContainer from './FooterBlockContainer'

interface FooterLinksBlockProps {
  title: string
  items: StaticByTitle[]
}

const additionalPageSlugs = [
  ADDITIONAL_STATIC_PAGES.contactUs.slug,
  ADDITIONAL_STATIC_PAGES.blog.slug,
  ADDITIONAL_STATIC_PAGES.helpCenter.slug
]

const FooterLinksBlock: RFC<FooterLinksBlockProps> = ({ title, items }) => {
  return (
    <FooterBlockContainer title={title}>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          a: {
            fontFamily: 'Poppins',
            fontWeight: 300,
            fontSize: 14,
            lineHeight: '20px',
            textDecoration: 'none',
            cursor: 'pointer',

            '&:hover': { textDecoration: 'underline' }
          }
        }}
      >
        {items.map((item) => {
          const isAdditionalPage = additionalPageSlugs.includes(item.slug)
          const link = isAdditionalPage ? `/${item.slug}` : `/info/${item.slug}`

          return (
            <Link key={item.title} prefetch={false} href={link}>
              {item.title}
            </Link>
          )
        })}
      </Stack>
    </FooterBlockContainer>
  )
}

export default FooterLinksBlock
