import { MessageDescriptor } from 'react-intl'

import { ADDITIONAL_STATIC_PAGES } from 'app/utils/constants/staticPage.constants'
import { STATIC_PAGE_VISIBILITY } from 'app/utils/constants/status.constants'

interface SelectStaticPagesProps {
  data: StaticPages[]
  visibility: StaticPageVisibility
}

export const selectStaticPages = ({
  data,
  visibility
}: SelectStaticPagesProps): StaticPages[] => {
  const hiddenVisibility =
    visibility === STATIC_PAGE_VISIBILITY.marketplace
      ? STATIC_PAGE_VISIBILITY.landing
      : STATIC_PAGE_VISIBILITY.marketplace

  return data?.map((item) => {
    const staticPages = item.static_pages.filter(
      (staticPage) => staticPage.visibility !== hiddenVisibility
    )

    return { ...item, static_pages: staticPages }
  })
}

interface ImplementAdditionalStaticPageConfig {
  data?: StaticPages[]
  pages: StaticByTitle[]
  toColumnNumber: number
}
export const implementAdditionalStaticPage = ({
  data,
  pages,
  toColumnNumber
}: ImplementAdditionalStaticPageConfig) =>
  data?.reduce((acc, item, index) => {
    if (index + 1 === toColumnNumber) {
      acc[index] = {
        name: item.name,
        static_pages: [...pages, ...item?.static_pages]
      }
    } else {
      acc.push(item)
    }

    return acc
  }, [] as StaticPages[])

export const formatDefaultStaticPage = (
  staticPage: (typeof ADDITIONAL_STATIC_PAGES)[keyof typeof ADDITIONAL_STATIC_PAGES],
  formatMessage: (intlObject: MessageDescriptor) => string
): StaticByTitle => ({ ...staticPage, title: formatMessage(staticPage.title) })
