import Link from 'next/link'

import { Box, Container } from '@mui/material'

import { useGetListingCategories } from 'app/services/Query/listing/listing.query'

const SearchCategories = () => {
  const { data: categories } = useGetListingCategories()

  if (!categories?.length) return null

  return (
    <Box sx={{ backgroundColor: 'background.dark' }}>
      <Container
        maxWidth="lg"
        sx={{
          py: 3,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          a: {
            fontFamily: 'Poppins',
            fontWeight: 300,
            fontSize: 14,
            lineHeight: '20px',
            textDecoration: 'none',
            cursor: 'pointer',

            '&:hover': { textDecoration: 'underline' }
          }
        }}
      >
        {categories?.map((category, index) => {
          const showDivider = index !== categories.length - 1
          return (
            <Box key={category.id} sx={{ whiteSpace: 'nowrap' }}>
              <Link href={`/${category.slug}`}>{category.name}</Link>

              {showDivider && (
                <Box component="span" sx={{ ml: 1 }}>
                  •
                </Box>
              )}
            </Box>
          )
        })}
      </Container>
    </Box>
  )
}

export default SearchCategories
